import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { Jumbotron, Grid, Row, Col, Image, Button } from 'react-bootstrap';
import Home from './components/Home/Home';
import CustomNavbar from './components/CustomNavbar/CustomNavbar';
import Karaktarerna from './components/Karaktarerna/Karaktarerna';
import Upphovspersoner from './components/Upphovspersoner/Upphovspersoner';
import Bocker from './components/Bocker/Bocker';
import Footer from './components/Footer/Footer';
import Licens from './components/Licens/Licens';
import Forlag from './components/Forlag/Forlag';
import Historia from './components/Historia/Historia';
import Aterforsaljare from './components/Aterforsaljare/Aterforsaljare';
import Snabbfakta from './components/Snabbfakta/Snabbfakta';
import Medverkande from './components/Medverkande/Medverkande';
import AltNavbar from './components/AltNavbar/AltNavbar';
import Kontakt from './components/Kontakt/Kontakt';
import Integritet from './components/Integritet/Integritet';
import Youtube from './components/Youtube/Youtube';
import Toolbar from './components/Toolbar/Toolbar';
import SideDrawer from './components/SideDrawer/SideDrawer';
import Backdrop from './components/Backdrop/Backdrop'

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sideDrawerOpen: false
    };
    this.drawerToggleClickHandler = this.drawerToggleClickHandler.bind(this);
    this.backdropClickHandler = this.backdropClickHandler.bind(this);
  }

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return {sideDrawerOpen: !prevState.sideDrawerOpen}
    });
  };

  backdropClickHandler = () => {
    this.setState({sideDrawerOpen: false});
  };

  render() {

    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler}/>;
    }

    return (
      <Router>
        <div className="app">
          <Toolbar drawerClickHandler={this.drawerToggleClickHandler}/>
          <SideDrawer show={this.state.sideDrawerOpen}/>
          {backdrop}
          <div className="main">
            <Route exact path="/" component={Home} />
            <Route path="/karaktarerna" component={Karaktarerna} />
            <Route path="/upphovspersoner" component={Upphovspersoner} />
            <Route path="/bocker" component={Bocker} />
            <Route path="/licens" component={Licens} />
            <Route path="/forlag" component={Forlag} />
            <Route path="/historia" component={Historia} />
            <Route path="/aterforsaljare" component={Aterforsaljare} />
            <Route path="/snabbfakta" component={Snabbfakta} />
            <Route path="/medverkande" component={Medverkande} />
            <Route path="/kontakt" component={Kontakt} />
            <Route path="/integritet" component={Integritet} />
            <Route path="/youtube" component={Youtube} />
          </div>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
