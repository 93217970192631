import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Jumbotron, Grid, Row, Col, Image, Button } from 'react-bootstrap';
import './Bocker.css'

export default class Bocker extends Component {

  render() {
    return (
      <Grid>
        <Row className="show-grid-book">
          <div className="show-book-1">
            <Image src="images/book/books_kojan_liggande_2000px.jpg" className="book-image"/>
          </div>
        </Row>
        <Row className="show-grid-info">
          <Col md="8" mdOffset="2">
            <h3>Bok 1</h3>

            <p><span>Snick & Snack Vännerna i Kungaskogen bygger en koja</span> är titeln på den första boken om vännerna. Här fäöler text om vad boken handlar om.</p>
            <p>Udiatus, etur sum hillo illique nonet aut qui corerfe riores vellaut etus inctus erci ut od electis is mos dolorest rerci ut labore, sitianima que sit verunt aut erumqui iderovitias ut officient ad quam, ommolupta dit, volum apient ab is aut dolores exceritatur asped qui consed exeratiis dolestist, comniet plam, sequis est, et licaboria nusdam, optatem dolorendae eius et quatemo luptatem venisin eatios etur, non cus et, aceptistia volorporis qui voluptam aut hicabore posaperia que.</p>

            <h4>Musiken</h4>

            <p>et est, con por maximpo rionesto inis utemporem nonestium ab inci comnihi lluptae pratio et harchiciis acia volorepel molorernat omnis inciminto este simusapedi cor alit dolut inumquatquo modis et faccumquam invelestiur re paruptae id quam volo te id que sit volupta quisci dolore il est harumquo experatibus volest, officiet porrum eatiundant quo dolorro dolorum re verchicid que doluptam, quassin venimet aut as res sam incit doluptatet occabo. Itatem apelitatecto in eatur sequam, nonse cus sunt harchit occaestrum voluptat eicim quam quat ad quod magnimus.</p>
            <p>Testrumquat as maioriora inulpar chillor sum non perspit, cuptius.</p>

            <h4>Författare</h4>

            <p>Ectorepres aut ime perferi onsequo dignis dipiend aector sunt, occabores quaeritibus demollese lacessit, que et int, conet res et dunte venihicimint aperum eatquis alia expereptate nimil maxim quamus none nis endanim invenecto et everia eum de eume pore nis dit odit faccusa piciatus, aute nones nim voluptas et plibeatem cusdae que re re non cus elibusam, vella volor simus vollor acillut ad ut repres di corro is pre occae cum voluptas dolentio quos volore est officitassum expera non nimintia dolupti busdam enes exeriant.</p>
            <p>Lite övrigt om berättarröst, illustratör etc.cum et volorum harum quideli bernamet rerum consequiae di doluptam veliqui iur rectur secepre ctotas ipsam, nonsequ osant.</p>
          </Col>
        </Row>
        <Row className="line-container">
          <div className="line"></div>
        </Row>
        <Row className="show-grid-book">
          <div className="show-book-2">
            <Image src="images/book/books_utflykt_liggande_2000px.jpg" className="book-image"/>
          </div>
        </Row>
        <Row className="show-grid-info">
          <Col md="8" mdOffset="2">
            <h3>Bok 2</h3>

            <p><span>Snick & Snack Vännerna i Kungaskogen bygger en koja</span> är titeln på den första boken om vännerna. Här fäöler text om vad boken handlar om.</p>
            <p>Udiatus, etur sum hillo illique nonet aut qui corerfe riores vellaut etus inctus erci ut od electis is mos dolorest rerci ut labore, sitianima que sit verunt aut erumqui iderovitias ut officient ad quam, ommolupta dit, volum apient ab is aut dolores exceritatur asped qui consed exeratiis dolestist, comniet plam, sequis est, et licaboria nusdam, optatem dolorendae eius et quatemo luptatem venisin eatios etur, non cus et, aceptistia volorporis qui voluptam aut hicabore posaperia que.</p>

            <h4>Musiken</h4>

            <p>et est, con por maximpo rionesto inis utemporem nonestium ab inci comnihi lluptae pratio et harchiciis acia volorepel molorernat omnis inciminto este simusapedi cor alit dolut inumquatquo modis et faccumquam invelestiur re paruptae id quam volo te id que sit volupta quisci dolore il est harumquo experatibus volest, officiet porrum eatiundant quo dolorro dolorum re verchicid que doluptam, quassin venimet aut as res sam incit doluptatet occabo. Itatem apelitatecto in eatur sequam, nonse cus sunt harchit occaestrum voluptat eicim quam quat ad quod magnimus.</p>
            <p>Testrumquat as maioriora inulpar chillor sum non perspit, cuptius.</p>

            <h4>Författare</h4>

            <p>Ectorepres aut ime perferi onsequo dignis dipiend aector sunt, occabores quaeritibus demollese lacessit, que et int, conet res et dunte venihicimint aperum eatquis alia expereptate nimil maxim quamus none nis endanim invenecto et everia eum de eume pore nis dit odit faccusa piciatus, aute nones nim voluptas et plibeatem cusdae que re re non cus elibusam, vella volor simus vollor acillut ad ut repres di corro is pre occae cum voluptas dolentio quos volore est officitassum expera non nimintia dolupti busdam enes exeriant.</p>
            <p>Lite övrigt om berättarröst, illustratör etc.cum et volorum harum quideli bernamet rerum consequiae di doluptam veliqui iur rectur secepre ctotas ipsam, nonsequ osant.</p>
          </Col>
        </Row>
      </Grid>
    )
  }
}
