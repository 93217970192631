import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Image} from 'react-bootstrap';

import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';
import './Toolbar.css';


const toolbar = props => (
  <div className="toolbar">
    <nav className="toolbar_nav">
      <div className="toolbar_logo">
        <Link to="/" >
          <Image src="images/Welcome/flagga_kungaskogen.png" responsive className="toolbar-logo"/>
        </Link>
      </div>

      <div className="spacer"></div>

      <div className="menubar-container">
        <ul className="menubar">
          <li className="dropdown">
            <a>OM</a>
            <ul className="dropdown-items">
              <li><Link to="/historia">Vår Historia</Link></li>
              <li><Link to="/snabbfakta">Snabbfakta</Link></li>
              <li><Link to="/karaktarerna">Karaktärer</Link></li>
              <li><Link to="/bocker">Böcker</Link></li>
              <li><Link to="/forlag">Förlag</Link></li>
              <li><Link to="/aterforsaljare">Återförsäljare</Link></li>
              <li><Link to="/upphovspersoner">Upphovspersoner</Link></li>
              <li><Link to="/medverkande">Medverkande</Link></li>
              <li><Link to="/licens">Licenser</Link></li>
            </ul>
          </li>

          <li className="dropdown">
            <a>PRODUKTER</a>
            <ul className="dropdown-items">
              <li><Link to="/">Produkter</Link></li>
              <li><Link to="/">Webshop</Link></li>
            </ul>
          </li>

          <li className="dropdown">
            <a>MUSIK/MEDIA</a>
            <ul className="dropdown-items">
              <li><Link to="/">Musik</Link></li>
              <li><Link to="/">Youtube</Link></li>
              <li><Link to="/">Appar</Link></li>
              <li><Link to="/">Musikspelare</Link></li>
              <li><Link to="/">Skivor</Link></li>
              <li><Link to="/">Studio</Link></li>
              <li><Link to="/">Musikförlag/Label</Link></li>
              <li><Link to="/">Radiostationer</Link></li>
              <li><Link to="/">Kanaler</Link></li>
              <li><Link to="/">Upphovspersoner</Link></li>
            </ul>
          </li>

          <li className="dropdown">
            <a>TEATER</a>
            <ul className="dropdown-items">
              <li><Link to="/">Bioteater</Link></li>
              <li><Link to="/">Raffe Räv Hälsar På</Link></li>
              <li><Link to="/">Bokning</Link></li>
              <li><Link to="/">Biografer</Link></li>
              <li><Link to="/">Arrangörer</Link></li>
              <li><Link to="/">Teatergrupper</Link></li>
              <li><Link to="/">Egna Elevföreställningar På Skola/Förskola</Link></li>
              <li><Link to="/">Så Här Tycker Andra</Link></li>
            </ul>
          </li>

          <li className="dropdown">
            <a>ANVÄNDARE</a>
            <ul className="dropdown-items">
              <li><Link to="/">Förskola/Skola</Link></li>
              <li className="dropdown-sub">
                <ul>
                  <li>Produkter</li>
                  <li>Tips</li>
                  <li>Feedback</li>
                  <li>Handledning/Access</li>
                </ul>
              </li>
              <li><Link to="/">Privata/Hem</Link></li>
              <li><Link to="/">Övrig Användare</Link></li>
              <li className="dropdown-sub">
                <ul>
                  <li>Produkter</li>
                  <li>Tips</li>
                  <li>Feedback</li>
                  <li>Handledning/Access</li>
                </ul>
              </li>
            </ul>
          </li>

          <li className="dropdown">
            <a>ÖVRIGT</a>
            <ul className="dropdown-items">
              <li><Link to="/">Länkar</Link></li>
              <li><Link to="/">Nyheter</Link></li>
              <li><Link to="/">Copyright</Link></li>
              <li><Link to="/">Nöjen Och Barnkultur</Link></li>
              <li><Link to="/">Arrangörer Barn</Link></li>
              <li><Link to="/">Utländsk Marknad</Link></li>
              <li><Link to="/">Vanliga Frågor</Link></li>
            </ul>
          </li>

          <li className="dropdown access"><a href="http://wasaplay.se/access/logform.php">ACCESS</a></li>
        </ul>
      </div>

      <div className="toolbar_toggle-button">
        <DrawerToggleButton click={props.drawerClickHandler}/>
      </div>
    </nav>
  </div>
);

export default toolbar;
